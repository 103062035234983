import React, { useState, useEffect,useRef } from 'react';
import './App.css'; // Create App.css for styling
import LoginPage from './LoginPage'; // Create LoginPage.js component

function App() {
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [likes, setLikes] = useState({});
  const [comments, setComments] = useState({});
  const [lastUpdated, setLastUpdated] = useState(new Date().toLocaleDateString());
  const [selectedImage, setSelectedImage] = useState(null); // State for modal image

  const fullSizeImage = "whatsnext.jpg"; // Or whatever your image name is
  const fullSizeImageRef = useRef(null); // Create a ref for the full-size image

  useEffect(() => {
  if (fullSizeImageRef.current) {
    const img = fullSizeImageRef.current;
    img.onload = () => {
      const aspectRatio = img.naturalWidth / img.naturalHeight;
      const container = img.parentElement;
      const calculatedHeight = container.offsetWidth / aspectRatio;
      container.style.height = `${calculatedHeight}px`;
      console.log("Container Height:", calculatedHeight, "px"); // Add this line
    };
  }
}, [selectedTopic]);



  const milestones = {
    "Concept & Design": {
      images: [
        { url: "concept.jpg", caption: "Gemini Rendering", id: "concept" },
        { url: "original.jpg", caption: "What we started with", id: "original" },
      ],
      text: "The initial phase involved brainstorming and creating digital renders of the final design.",
    },
    "The Gentle Way...":{
      images: [
        {url: "failed1.jpg", caption: "Trying to save the front end", id: "failed1"},
        {url: "failed2.jpg", caption: "Something toweld the cross member to that bolts in...", id: "failed2"},
        {url: "failed3.jpg", caption: "Working with the new cross memeber", id: "failed3"},
        {url: "failed4.jpg", caption: "Getting somewhere, brakes look damn good", id: "failed4"},
        {url: "failed5.jpg", caption: "I mean really, that looks pretty sweet", id: "failed5"},
        {url: "failed6.jpg", caption: "Can we fit a ford 9 \" where the old axle was?", id: "failed6"},
        {url: "failed7.jpg", caption: "This is looking too hard to be honest", id: "failed7"},
      ]
    },
    "The Harder Way...":{
      images: [
        {url: "frame1.jpg", caption: "So I had this diagram and thought \" let's make a chassis \"", id: "frame1"},
        {url: "frame2.jpg", caption: "So I began buying and cutting steel", id: "frame2"},
        {url: "frame3.jpg", caption: "Yes the \"frame table\"  is leveled", id: "frame3"},
        {url: "frame4.jpg", caption: "And then ...", id: "frame4"},
        {url: "frame5.jpg", caption: "Still goinh", id: "frame5"},
        {url: "frame6.jpg", caption: "C'mon that looks like a real chassis right?", id: "frame6"},
        {url: "frame7.jpg", caption: "Got a professional to finish weld the chassis", id: "frame7"},
        {url: "frame8.jpg", caption: "With the front suspension, brakes, and tires", id: "frame8"},
        {url: "frame9.jpg", caption: "More of the same because it's bad ass looking", id: "frame9"},
        {url: "frame10.jpg", caption: "Now to ge the rear axle in this mug", id: "frame10"},
        {url: "frame11.jpg", caption: "And there we go", id: "frame11"},
        {url: "frame12.jpg", caption: "Sweet, am I right?  I hope the measurements match the oirginal Vovlo schematic from the first pic ;-)", id: "frame12"},
        {url: "frame13..jpg", caption: "With some weldable primer on it", id: "frame13"},
        {url: "frame14.jpg", caption: "Smoooooooth", id: "frame14"}
    ]
    },
    "Chassis + Unibody = $#%^&*?":{
      images: [
        {url: "body1.jpg", caption: "Some night time prep for the big CUT tomorrow", id: "body1"},
        {url: "body2.jpg", caption: "Gotta brace up the car if you're cutting it up", id: "body2"},
        {url: "body3.jpg", caption: "Welp, no turning back", id: "body3"},
        {url: "body4.jpg", caption: "Still didn't know how much dents and bondo the body had in it yet", id: "body4"},
        {url: "body5.jpg", caption: "A Little frame/body forshadowing", id: "body5"},
        {url: "body6.jpg", caption: "You can almost see it", id: "body6"},
        {url: "body7.jpg", caption: "And... they are together", id: "body7"},
        {url: "body8.jpg", caption: "Plenty of room for a V8", id: "body8"},
        {url: "body9.jpg", caption: "Not bad looking", id: "body9"},
        {url: "body10.jpg", caption: "Look Ma, no floors", id: "body10"},
        {url: "body11.jpg", caption: "And no engine mounts", id: "body11"},
        {url: "body12.jpg", caption: "Where we are going, we don't need doors.  Oh wait, this ain't a stupid Jeep! ", id: "body12"},
        {url: "body13.jpg", caption: "Okay, I see you there.", id: "body13"},
        {url: "body14.jpg", caption: "Front support", id: "body14"},
        {url: "body15.jpg", caption: "Getting some structure back in the front", id: "body15"},
        {url: "body16.jpg", caption: "And that's pretty good to go", id: "body16"}
      ]
    },
    "Motor and Stuff": {
      images: [
        {url: "engine1.jpg", caption: "Okay, she needs a new coat of paint.  Bondo and paint will make it what it ain't. I mean...", id: "engine1"},
        {url: "engine2.jpg", caption: "I like the intake but still not sure.  It's tall", id: "engine2"},
        {url: "engine3.jpg", caption: "Fuel tank in and plummed to the engine", id: "engine3"},
        {url: "engine4.jpg", caption: "Engine in! ", id: "engine4"},
        {url: "engine5.jpg", caption: "See how tall?", id: "engine5"},
        {url: "engine6.jpg", caption: "Custom driveshaft balanced and installed", id: "engine6"},
        {url: "engine7.jpg", caption: "Nice ass", id: "engine7"},
        {url: "engine8.jpg", caption: "A little tail pipe action", id: "engine8"},
      ] 
    },
    "Interior Metal Work":{
      images: [
        {url: "interior1.jpg", caption: "T56 6 speed with the original volvo shifter knob", id: "interior1"},
        {url: "interior2.jpg", caption: "These are not fun to play with", id: "interior2"},
        {url: "interior3.jpg", caption: "Original tunnel I made vs. the new one to ensure there was room for seats", id: "interior3"},
        {url: "interior4.jpg", caption: "test fit", id: "interior4"},
        {url: "interior5.jpg", caption: "Had to make the trans tunnel in two parts", id: "interior5"},
        {url: "interior6.jpg", caption: "Seat fits AND the cleanup work around the shifter", id: "interior6"},
        {url: "interior7.jpg", caption: "New rear tubs, new rear seat back (separates interior from trunk), and rear seat area", id: "interior7"}
      ]
    },
    "What's Next":{
      images: [{url: "whatsnext.jpg", caption: "", id: "final1"}],
      text: "Finsish the interior Metal (1 wheel tub left and a little metal work to tie the floors into the back seat area.  The finish the trunk.  Then All new rear quarters, front right quarter, and front cowl.  That will straighten body up from 50 years of fender benders.  then the trunk metal, then the fron wheel inner fenders."
    }
  };

  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
  };

  const handleLike = (imageId) => {
    setLikes((prevLikes) => ({
      ...prevLikes,
      [imageId]: (prevLikes[imageId] || 0) + 1,
    }));
  };

  const handleCommentSubmit = (imageId, comment) => {
    setComments((prevComments) => ({
      ...prevComments,
      [imageId]: [...(prevComments[imageId] || []), comment],
    }));
  };

  const handleLoginClick = () => {
    setShowLogin(true);
  };

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    setShowLogin(false);
  };

  const handleLogout = () => {
      setIsLoggedIn(false);
  }

  const handleImageClick = (imageUrl) => {
    console.log(imageUrl);
    if (imageUrl !== fullSizeImage) {
      setSelectedImage(imageUrl);
    }
  };

    const closeModal = () => {
        setSelectedImage(null);
    };

  return (
    <div className="app">
      <header className="app-header">
        <span className="last-updated">Last Updated: {lastUpdated}</span>
        <img src={"logo.png"} alt="" className="header-logo" /> {/* Add the logo */}
        <div className="header-links">
          {isLoggedIn ? (
            <a href="#" onClick={handleLogout}>
              Logout
            </a>
          ) : (
            <a href="#" onClick={handleLoginClick}>
              Login
            </a>
          )}
          <a href="mailto:eric.scholz1975@gmail.com">
            Contact Me
          </a>
        </div>
      </header>

      <div className="app-content">
        <aside className="app-sidebar">
          <h2>Milestones</h2>
          <ul>
            {Object.keys(milestones).map((topic) => (
              <li key={topic} onClick={() => handleTopicClick(topic)}>
                {topic}
              </li>
            ))}
          </ul>
        </aside>

        <main className="app-main">
          {showLogin && <LoginPage onLoginSuccess={handleLoginSuccess} />}
          {!selectedTopic && ( // Show main logo if no topic is selected
          <div className="main-logo-container"> 
          <img
            ref={fullSizeImageRef}
            src={"mainlogo.jpg"}
            alt="Main Logo"
            className="main-logo-image" 
          />
        </div>
        )}
          {selectedTopic && (
            <div>
              <h2>{selectedTopic}</h2>
              <p>{milestones[selectedTopic].text}</p>
              <div className="image-grid">
                {milestones[selectedTopic].images.map((image) => (
                   <div key={image.id} className="image-item">
                   {image.url === fullSizeImage ? (
                       <div className='full-size-image-container'>
                           <img
                               src={image.url}
                               alt={image.caption}
                               className="full-size-image"
                           />
                       </div>
                   ) : (
                       <img
                           src={image.url}
                           alt={image.caption}
                           onClick={() => handleImageClick(image.url)}
                           className="clickable-image"
                       />
                   )}
                   <p>{image.caption}</p>
                   <button onClick={() => handleLike(image.id)}>
                       Like ({likes[image.id] || 0})
                   </button>
                   {isLoggedIn && (
                       <CommentSection
                           imageId={image.id}
                           onCommentSubmit={(comment) => handleCommentSubmit(image.id, comment)}
                           existingComments={comments[image.id] || []}
                       />
                   )}
               </div>
                ))}
              </div>
            </div>
          )}
        </main>
      </div>

      {selectedImage && (
        <div className="modal">
        <div className="modal-content">
          <span className="close-button" onClick={closeModal}>
            &times;
          </span>
          <img
            src={selectedImage}
            alt="Full Size"
            className="modal-image"
          />
        </div>
      </div>
      )}
    </div>
  );
}

function CommentSection({ imageId, onCommentSubmit, existingComments }) {
  // ... (CommentSection component remains the same)
}

export default App;